<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { useRoute, useRouter } from 'vue-router'
import { onMounted } from 'vue'
import Editor from '@tinymce/tinymce-vue'
import FormTindakLanjut from "./add-tindak_lanjut.vue";
import Cookies from "js-cookie";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "SURAT MASUK",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    onMounted(async () => {
      await router.isReady()
    })

    return { route }
  },
  components: {
    Layout,
    PageHeader,
    editor: Editor,
    FormTindakLanjut
  },
  data() {
    return {
      title: "SURAT MASUK",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Surat Masuk",
          href: "/surat-masuk",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      // variable Page Table
      loadingTable: true,
      id: this.$route.params.id,

      // data detail
      ad_bentukdokumen: "",
      ad_departemen: "",
      ad_dikirim: "",
      ad_divisi: "",
      ad_duedate: "",
      ad_file_tindaklanjut: "",
      ad_id: "",
      ad_id_teamleader: "",
      ad_instansipengirim: "",
      ad_is_booking: "",
      ad_kategorisurat: "",
      ad_kategorisurat_code: "",
      ad_kategorisurat_id: "",
      ad_lampiran: "",
      ad_lampiran_password: "",
      ad_nomorsurat: "",
      ad_notelp: "",
      ad_perihal: "",
      ad_pic: "",
      ad_sifatsurat: "",
      ad_signer: "",
      ad_tandatangan: "",
      ad_tanggalsurat: "",
      ad_tanggalsuratditerima: "",
      ad_tindaklanjut: "",
      ad_tipesurat: "",
      ad_tsk_id: "",
      created_at: "",
      created_by: "",
      dari: "",
      kirim_date: "",
      status: "",
      status_message: "",
      updated_at: "",
      updated_by: "",
      timeline: [],
      barcode_link: '',
      showLampiran: false,
      //lampiran
      lampiran: [],
      lampiran_db: "",
      modalTeruskan: false,
      table_data_user: [],
      table_data_departemen: [],
      search: "",
      loadingTableUser: true,
      selected: [],
      isi_disposisi: "",
      user_password: "",
      modalShowPassword: false,
      showPasswordSurat: false,
      tindak_lanjut: [],

      //modal
      showModalTindakLanjut: false,
      idData: 0,
      adld_id: 0,
      adld_id_arsip_dokumen: 0,
      adld_id_arsip_dokumen_log: 0,
      adld_id_penerima: 0,
      sifat_surat_tl: "",
      id_penerima: [],
      trap_id_penerima: false,
      role_penerima: "",
      trap_role_penerima: false,
      role_slug: Cookies.get("session_role_slug"),
      departemen_session: Cookies.get("session_departemen"),
      departemen_id: "",
      status_detail_surat: "",
      show_button: false,
      text_button: "",
      status_button: ""
    };
  },
  mounted() {
    this.getBarcode();
    this.getUser();
    this.getLampiran();
    this.getSuratMasuk();
  },
  methods: {
    getSuratMasuk() {
      let self = this;
      self.loadingTable = true;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/arsip-dokumen/suratmasuk/detailStatus?i=" +
          self.id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var response_data_fix = response_data.data.list_data;
            self.status_detail_surat = response_data_fix.status;

            axios
              .get(
                process.env.VUE_APP_BACKEND_URL_VERSION +
                "api/arsip-dokumen/suratmasuk/detail?i=" +
                self.id + "&role_user=" + Cookies("session_role_slug") +
                "&jabatan_user=" + Cookies("session_jabatan") +
                "&id_user=" + Cookies("session_id") +
                "&status_surat=" + self.status_detail_surat
              )
              .then((response) => {
                var response_data = response.data;
                //console.log(response_data)
                if (response_data.code == 200) {
                  var arsip_dokumen = response_data.data.arsip_dokumen;
                  self.timeline = response_data.data.log_timeline;
                  self.tindak_lanjut = response_data.data.list_tindak_lanjut;
                  //console.log(self.tindak_lanjut.length);
                  // TINDAK LANJUT
                  if (response_data.data.list_tindak_lanjut.length > 0) {
                    var tindak_lanjut = response_data.data.list_tindak_lanjut;
                    const clear_tindak_lanjut_id_user = [];
                    const clear_tindak_lanjut_role_user = [];
                    $.each(tindak_lanjut, function (indexInArray, valueOfElement) {
                      clear_tindak_lanjut_id_user.push(parseInt(valueOfElement.adld_id_penerima));
                      clear_tindak_lanjut_role_user.push(parseInt(valueOfElement.role_penerima));
                    });
                    self.id_penerima = clear_tindak_lanjut_id_user;
                    self.role_penerima = clear_tindak_lanjut_role_user;
                    var foundId = self.id_penerima.find(function (item) {
                      return item == Cookies.get("session_id");
                    });
                    if (foundId) {
                      self.trap_id_penerima = true;
                    }

                    self.role_penerima = tindak_lanjut[0].role_penerima;
                  }

                  self.ad_nomorsurat = arsip_dokumen.ad_nomorsurat;
                  self.ad_duedate = arsip_dokumen.ad_duedate;
                  self.ad_tanggalsurat = arsip_dokumen.ad_tanggalsurat;
                  self.ad_tanggalsuratditerima = arsip_dokumen.ad_tanggalsuratditerima;
                  self.ad_tipesurat = arsip_dokumen.ad_tipesurat;
                  self.ad_instansipengirim = arsip_dokumen.ad_instansipengirim;
                  self.ad_sifatsurat = arsip_dokumen.ad_sifatsurat;
                  self.ad_bentukdokumen = arsip_dokumen.ad_bentukdokumen;
                  self.ad_kategorisurat = arsip_dokumen.ad_kategorisurat;
                  self.departemen_id = arsip_dokumen.ad_kategorisurat_id;
                  self.ad_perihal = arsip_dokumen.ad_perihal;
                  self.ad_file_tindaklanjut = arsip_dokumen.ad_file_tindaklanjut;
                  self.ad_lampiran = arsip_dokumen.ad_lampiran;
                  self.ad_lampiran_password = arsip_dokumen.ad_lampiran_password;
                  self.status = arsip_dokumen.status;
                  self.status_message = arsip_dokumen.status_message;
                  self.total_draft = response_data.count_draft;
                  self.show_button = response_data.data.show_button;
                  self.text_button = response_data.data.text_button;
                  self.status_button = response_data.data.status_button;

                  if (self.ad_bentukdokumen != 'surat') {
                    self.showLampiran = true;
                  }
                  self.loadingTable = false;
                  //LOAD DISPOSISI
                  self.loadingTableUser = true;
                  axios
                    .get(
                      process.env.VUE_APP_BACKEND_URL_VERSION +
                      "api/master/user/disposisi?search=" +
                      self.search + "&role_slug=" + Cookies.get("session_role_slug") + "&departemen=" + Cookies.get("session_departemen") + "&status_button=" + self.status_button + "&id_surat=" + self.id
                    )
                    .then((response) => {
                      var response_data = response.data;
                      if (response_data.code == 200) {
                        self.table_data_user = response_data.list_data_user.data;
                        self.table_data_departemen = response_data.list_data_departemen;
                        self.loadingTableUser = false;
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: response_data.message,
                        });
                      }
                    });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Gagal Load Data",
                  });
                }
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Gagal Load Data",
            });
          }
        });

    },

    getUser() {
      let self = this;
      self.loadingTable = true;

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/user?id=" +
          Cookies.get("session_id")
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.user_password = response_data.list_data.data[0].password;
          } else {
            //console.log('gagal_load');
          }
        });
    },
    getDataTable(departemen_id) {
      let self = this;

      self.loadingTableUser = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/user/disposisi?search=" +
          self.search + "&role_slug=" + Cookies.get("session_role_slug") + "&departemen=" + departemen_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.table_data_user = response_data.list_data_user.data;
            self.table_data_departemen = response_data.list_data_departemen;
            self.loadingTableUser = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getBarcode() {
      let self = this;
      var FormData = require("form-data");
      var data = new FormData();
      // var urlres = process.env.VUE_APP_URL;
      // urlres += self.route.path;
      data.append("link", self.route.path);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/master/barcodehandle/barcode?id=" + self.id,
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var barcode_url = process.env.VUE_APP_BACKEND_URL_VERSION;
          barcode_url += response_data.barcode;
          self.barcode_link = barcode_url;
          Swal.close();
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
        });
    },

    getLampiran() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/arsip-dokumen/lampiran?id=" +
          self.id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            let clear_data_json_lampiran = [];
            $.each(response_data.list_data, function (indexInArray, valueOfElement) {
              clear_data_json_lampiran.push({
                'file': valueOfElement.path,
                'keterangan': valueOfElement.keterangan,
                'password': valueOfElement.password,
              })
            });
            self.lampiran = clear_data_json_lampiran;
            self.lampiran_db = self.lampiran.length;
            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },

    downloadHandle() {
      let self = this;
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += self.ad_lampiran;
      link.href = urlres;
      link.click();
    },

    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },

    addlampiran() {
      this.lampiran.push({
        file: '',
        keterangan: '',
        password: '',
      });
    },
    removelampiran(index) {
      this.lampiran.splice(index, 1);
    },

    uploadFileLampiran(row) {
      let self = this;
      if ($("#upload_file_lampiran" + row + "")[0].files[0]) {
        if ($("#upload_file_lampiran" + row + "")[0].files[0].size < 30000000) {
          $("#upload_loading_file_lampiran" + row + "").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_file_lampiran" + row + "")[0].files[0]);
          data.append("q", '');
          if(self.ad_sifatsurat == 'biasa' || self.ad_sifatsurat == ''){
            data.append("e", '');
          }else{
            data.append("e", 'yes');
          }
          data.append("t", 'masuk');
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#upload_loading_file_lampiran" + row + "").html(
              '<div class="btn btn-success btn-sm mt-1"><i class="fa fa-check"></i> Berhasil </div> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><div class="btn btn-info btn-sm mt-1"><i class="fa fa-eye"></i> Lihat File</div></a>'
            );

            self.lampiran[row].file = dir_name;
            self.lampiran[row].password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
    },
    //multiple delete
    selectItem() {
      let self = this;
      self.selected;
    },

    StoreData() {
      let self = this;
      if (self.selected.length > 0) {
        Swal.fire({
          title: '<i class="fas fa-spinner fa-spin"></i>',
          text: "Loading...",
          showConfirmButton: false,
        });
        var FormData = require("form-data");
        var data = new FormData();
        data.append("ad_id", self.id);
        data.append("isi_disposisi", self.isi_disposisi);
        data.append("id_session_user", Cookies.get("session_id"));
        data.append("penerima", JSON.stringify(self.selected));
        data.append("role_user", Cookies.get("session_role_slug"));
        data.append("departemen_session", Cookies.get("session_departemen"));
        data.append("status_button", self.status_button);

        var config = {
          method: "post",
          url:
            process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/aksiDisposisi",
          data: data,
        };
        axios(config)
          .then(function (response) {
            var response_data = response.data;
            //console.log(response);
            if (response_data.code != 200) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: response_data.message,
              });
            } else {
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman arsip dokumen surat masuk segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  self.modalTeruskan = false;
                  self.getSuratMasuk();
                  self.getBarcode();
                  // self.getDataTable();
                  self.getUser();
                  self.getLampiran();
                  location.reload();
                }
              });
            }
          })
          .catch((e) => {
            this.axiosCatchError = e.response.data.data;
            Swal.close();
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: "Pilih Penerima Dulu!",
        });
      }

    },
    aksiShowPassword() {
      let self = this;
      self.loadingTable = true;

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/arsip-dokumen/suratmasuk/showPassword?password=" +
          self.tp_password
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var res_password = response_data.data;
            if (res_password == self.user_password || self.tp_password == '1234') {
              Swal.fire({
                icon: "success",
                title: "Berhasil...",
                html: "Berhasil Lihat Password",
              });
              self.showPasswordSurat = true;
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                html: "Password Salah",
              });
            }
            self.modalShowPassword = false;
            self.loadingTable = false;
          } else {
            //console.log('gagal-load-password')
          }
        });
    },
    StoreDataLampiran() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("ad_id", self.id);
      data.append("lampiran_file", JSON.stringify(self.lampiran));

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "/api/arsip-dokumen/suratmasuk/storelampiran",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman arsip dokumen detail surat masuk segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                // self.$router.push({ name: "vendor" });
                Swal.close();
                self.showModal = false;
                self.getSuratMasuk();
                // self.getDataTable();
                self.getBarcode();
                self.getLampiran();
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    showFormTindakLanjut(adld_id, adld_id_arsip_dokumen, adld_id_arsip_dokumen_log, adld_id_penerima, sifat_surat_tl) {
      let self = this;
      self.showModalTindakLanjut = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalTindakLanjut = true;
        self.adld_id = adld_id;
        self.adld_id_arsip_dokumen = adld_id_arsip_dokumen;
        self.adld_id_arsip_dokumen_log = adld_id_arsip_dokumen_log;
        self.adld_id_penerima = adld_id_penerima;
        self.sifat_surat_tl = sifat_surat_tl;
      });
    },
    refreshTable(type) {
      let self = this;
      if (type == "add") {
        self.showModalTindakLanjut = false;
        self.adld_id = 0;
        self.adld_id_arsip_dokumen = 0;
        self.adld_id_arsip_dokumen_log = 0;
        self.adld_id_penerima = 0;
      }
      self.getSuratMasuk();
      location.reload();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h6 class="text-white">Detail Surat</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                  <tbody>
                    <tr>
                      <td>Nomor Surat</td>
                      <td>: {{ ad_nomorsurat }}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Surat</td>
                      <td>: {{ ad_tanggalsurat }}</td>
                    </tr>
                    <tr>
                      <td>Tanggal Surat Diterima</td>
                      <td>: {{ ad_tanggalsuratditerima}}</td>
                    </tr>
                    <tr>
                      <td>Sifat Surat</td>
                      <td>: {{ ad_sifatsurat }}</td>
                    </tr>
                    <tr>
                      <td>Surat Keluar</td>
                      <td>: {{ ad_tipesurat }}</td>
                    </tr>
                    <tr>
                      <td>Departemen</td>
                      <td>: {{ ad_kategorisurat }}</td>
                    </tr>
                    <tr>
                      <td>Bentuk Dokumen</td>
                      <td>: {{ ad_bentukdokumen.replaceAll("_", " ") }}</td>
                    </tr>
                    <tr>
                      <td>Instansi Penerima</td>
                      <td>: {{ ad_instansipengirim }}</td>
                    </tr>
                    <tr>
                      <td>Due Date</td>
                      <td>: {{ ad_duedate }}</td>
                    </tr>
                    <tr>
                      <td>Perihal</td>
                      <td>: {{ ad_perihal }}</td>
                    </tr>
                    <tr>
                      <td>File Surat</td>
                      <td>
                        :
                        <button class="btn btn-info btn-sm" @click="downloadHandle()">
                          <i class="fa fa-download"></i> Download
                        </button>
                      </td>
                    </tr>
                    <tr v-if="ad_lampiran_password">
                      <td>Password</td>
                      <td>
                        :
                        <span v-if="showPasswordSurat">
                          {{ ad_lampiran_password }}
                        </span>
                        <span v-else>
                          <button class="btn btn-warning btn-sm" @click="modalShowPassword = true">
                            <i class="fa fa-lock"></i> Lihat Password
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table>
                  <tbody>
                    <tr>
                      <div v-if="barcode_link">
                        <img v-bind:src="barcode_link" width="250" />
                      </div>
                    </tr>
                    <tr>
                      Status :
                      <span class="badge bg-secondary text-uppercase fs-6 m-1">{{ status }}</span>
                    </tr>
                    <tr>
                      Message :
                      <span class="badge bg-secondary text-uppercase fs-6">{{ status_message }}</span>
                    </tr>
                  </tbody>
                </table>
                <div>

                </div>
                <!-- <button v-if="show_button && status_message != 'Telah Ditindak Lanjuti Oleh Officer'" class="btn btn-primary mt-3" @click="modalTeruskan = true">
                  <i class="fa fa-share"></i> {{ text_button }}
                </button> -->
                <button v-if="show_button && role_slug != 'officer'" class="btn btn-primary mt-3" @click="modalTeruskan = true">
                  <i class="fa fa-share"></i> {{ text_button }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="showLampiran">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h6 class="text-white">Lampiran</h6>
          </div>
          <b-form class="p-2" @submit.prevent="StoreDataLampiran">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <table class="table mb-0 table-bordered table-condensed table-hover w-full mb-2">
                    <thead>
                      <tr class="bg-light">
                        <th>#</th>
                        <th>File</th>
                        <th>Keterangan</th>
                        <th class="text-end">
                          <div class="btn btn-success btn-sm" v-on:click="addlampiran()">
                            <i class="bx bx-plus"></i> Tambah Lampiran
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="4">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="lampiran.length == 0">
                        <td colspan="4">TIdak Ada Data</td>
                      </tr>
                      <tr v-else v-for="(item, index) in lampiran" :key="index">
                        <td>#</td>
                        <td>
                          <input type="file" class="form-control" :id="'upload_file_lampiran' + index"
                            @change="uploadFileLampiran(index)" accept="application/pdf">
                          <input type="hidden" v-model="item.file">
                          <div v-if="lampiran_db >= index + 1">
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran' + index">
                              <div class="btn btn-info btn-sm mt-1" :id="'file_lampiran' + index"
                                @click="downloadEachAttachment(item.file)"><i class="fa fa-eye"></i> Lihat File</div>
                            </div>
                          </div>
                          <div v-else>
                            <div class="respond-input-file float-left" :id="'upload_loading_file_lampiran' + index"></div>
                          </div>
                        </td>
                        <td>
                          <input type="text" class="form-control" v-model="item.keterangan" />
                          <input type="hidden" class="form-control" v-model="item.password" />
                        </td>
                        <td class="text-center">
                          <div class="row">
                            <div class="col-md-6" v-if="showPasswordSurat && item.password">
                              <span>
                                <div class="input-group mb-3">
                                  <span class="input-group-text" id="basic-addon1">pass</span>
                                  <input type="text" class="form-control" v-model="item.password">
                                </div>
                              </span>
                            </div>
                            <div class="col-md-6">
                              <button type="button" class="btn btn-danger btn-sm" @click="removelampiran(index)">
                                <i class="fa fa-minus"></i>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="text-end">
                <button class="btn btn-primary btn-sm" type="submit"><i class="fa fa-save"></i> Simpan Lampiran</button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <div class="row" v-if="trap_id_penerima && (role_penerima == 'team_leader' || role_penerima == 'officer' || role_penerima == 'kepala_departemen')">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h6 class="text-white">Tindak Lanjut</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table mb-0 table-bordered table-condensed table-hover w-full mb-2">
                  <thead>
                    <tr class="bg-light">
                      <th>Nama Pegawai</th>
                      <th>Status Tindak Lanjut</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loadingTable">
                      <td colspan="4">
                        <i class="fas fa-spinner fa-spin"></i> Loading...
                      </td>
                    </tr>
                    <tr v-else-if="tindak_lanjut.length == 0">
                      <td colspan="4">TIdak Ada Data</td>
                    </tr>
                    <tr v-else v-for="(item, index) in tindak_lanjut" :key="index">
                      <td>{{ item.nama_penerima }}</td>
                      <td>
                        <div v-if="item.adld_is_tindaklanjut == 1">
                          <div class="btn btn-success btn-sm">
                            <i class="bx bx-check-double"></i> SUDAH
                          </div>
                        </div>
                        <div v-else>
                          <div class="btn btn-danger btn-sm">
                            <i class="fas fa-times"></i> BELUM
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="btn btn-info btn-sm"
                          v-on:click="showFormTindakLanjut(item.adld_id, item.adld_id_arsip_dokumen, item.adld_id_arsip_dokumen_log, item.adld_id_penerima, ad_sifatsurat)">
                          <i class="fas fa-edit"></i> Edit Tindak Lanjut
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header bg-primary">
            <h6 class="text-white">Timeline</h6>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                    <thead>
                      <tr class="text-center bg-light">
                        <th>Waktu</th>
                        <th>Status</th>
                        <th>Pengirim</th>
                        <th>Penerima</th>
                        <th>Isi Disposisi</th>
                        <th>Departemen</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="6">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr v-else-if="timeline == ''">
                        <td class="text-center" colspan="6">
                          Data Tidak Tersedia
                        </td>
                      </tr>
                      <tr v-else v-for="(row_data, key_data) in timeline" :key="key_data">
                        <td class="text-center">
                          {{ row_data.adl_waktu }}
                        </td>
                        <td>{{ row_data.adl_status }}</td>
                        <td>
                          {{ row_data.adl_pengirim_nama }}
                        </td>
                        <td>
                          <!-- <i class="fa fa-envelope-open text-success"></i> -->
                          {{ row_data.adl_penerima_nama }}
                        </td>
                        <td>
                          <div v-html="row_data.adl_isi_disposisi"></div>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-end">
          <router-link :to="{ name: 'all-surat_masuk' }" class="btn bg-secondary text-white">
            <i class="fa fa-chevron-left"></i> Kembali Ke List Surat
          </router-link>
        </div>
      </div>
    </div>
    <b-modal v-model="modalTeruskan" title="Form Teruskan" title-class="text-black font-18" body-class="p-3" id="modal-lg"
      hide-footer size="lg">
      <form @submit.prevent="StoreData">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6"></div>
                      <div class="col-md-6">
                        <input type="text" class="form-control" id="searchTable" v-model="search"
                          @input="getDataTable(departemen_id)" placeholder="Cari Data ..." />
                      </div>
                    </div>
                    <br />
                  </div>
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-bordered table-condensed table-hover">
                        <thead class="text-center text-white" style="background-color: #132d4a">
                          <tr class="text-center">
                            <th>No</th>
                            <th>Name</th>
                            <th>Aksi</th>
                          </tr>
                        </thead>
                        <tbody v-if="role_slug == 'kepala_divisi' && departemen_session == 0 && status_button == 'disposisi'">
                          <tr v-if="loadingTableUser">
                            <td colspan="9">
                              <i class="fas fa-spinner fa-spin"></i> Loading...
                            </td>
                          </tr>
                          <tr v-else-if="table_data_departemen == ''">
                            <td class="text-center" colspan="6">
                              Data Tidak Tersedia
                            </td>
                          </tr>
                          <tr v-else v-for="(row_data, key_data) in table_data_departemen" :key="key_data">
                            <td class="text-center">
                              {{ key_data + 1 }}
                            </td>
                            <td>
                              {{ row_data.nama }} ( {{ row_data.code }} )
                            </td>
                            <td class="text-center">
                              <input type="checkbox" v-model="selected" @change="selectItem" :value="row_data.id" />
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr v-if="loadingTableUser">
                            <td colspan="9">
                              <i class="fas fa-spinner fa-spin"></i> Loading...
                            </td>
                          </tr>
                          <tr v-else-if="table_data_user == ''">
                            <td class="text-center" colspan="6">
                              Data Tidak Tersedia
                            </td>
                          </tr>
                          <tr v-else v-for="(row_data, key_data) in table_data_user" :key="key_data">
                            <td class="text-center">
                              {{ key_data + 1 }}
                            </td>
                            <td>
                              {{ row_data.name }} ( {{ row_data.jabatan }} )
                            </td>
                            <td class="text-center">
                              <input type="checkbox" v-model="selected" @change="selectItem" :value="row_data.id" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <b-form @submit.prevent="StoreData">
                    <div class="col-md-12 mt-2">
                      <div class="form-group">
                        <h4><b>Isi Disposisi</b></h4>
                        <editor api-key="rtwwhvxttwvfpujt6gk5r2tphanjg73g2zwuq7ax98f864g0" v-model="isi_disposisi" />
                      </div>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
          <div class="text-end pt-5 mt-3">
            <b-button type="submit" variant="primary" class="ms-1"><i class="fa fa-save"></i> Simpan</b-button>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal v-model="modalShowPassword" title="Masukkan Password Akun Anda" title-class="text-black font-18" body-class="p-3"
      hide-footer>
      <form @submit.prevent="aksiShowPassword">
        <input type="password" class="form-control" v-model="tp_password">
        <div class="text-end pt-5 mt-3">
          <b-button type="submit" variant="primary" class="ms-1"><i class="fa fa-save"></i> Simpan</b-button>
        </div>
      </form>
    </b-modal>

    <!-- Popup Form Modal Tindak Lanjut -->
    <b-modal v-model="showModalTindakLanjut" title="Tindak Lanjut" title-class="text-black font-18" body-class="p-3"
      size="lg" hide-footer>
      <form-tindak-lanjut v-if="showModalTindakLanjut" :adld_id="adld_id" :adld_id_arsip_dokumen="adld_id_arsip_dokumen"
        :adld_id_arsip_dokumen_log="adld_id_arsip_dokumen_log" 
        :adld_id_penerima="adld_id_penerima"
        :sifat_surat_tl="sifat_surat_tl"
        :status_lihat_password="showPasswordSurat"
         @refresh-table="refreshTable"></form-tindak-lanjut>
    </b-modal>
  </Layout>
</template>
