<script>
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";

export default {
  name: "form-add",

  props: ["adld_id", "adld_id_arsip_dokumen", "adld_id_arsip_dokumen_log", "adld_id_penerima", "sifat_surat_tl", "status_lihat_password"],
  data() {
    return {
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      master_role: [],
      akses_login: ["Ya", "Tidak"],
      role_selected: "",
      adld_id_tl: this.adld_id,
      adld_id_arsip_dokumen_tl: this.adld_id_arsip_dokumen,
      adld_id_arsip_dokumen_log_tl: this.adld_id_arsip_dokumen_log,
      adld_id_penerima_tl: this.adld_id_penerima,
      sifat_surat: this.sifat_surat_tl,
      is_password: this.status_lihat_password,
      master_jabatan: [],
      master_departement: [],
      jabatan_selected: "",
      departemen_selected: "",
      akses_login_selected: "",
      showJabatan: false,
      showDepartemen: false,
      status: 'ENABLE',
      tindak_lanjut: [],
      lampiran_db: 0,
      loadingTable: true
    };
  },
  mounted() {
    this.getLampiran();
  },
  methods: {
    getLampiran(){
      this.loadingTable= true;
      axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "api/arsip-dokumen/suratmasuk/lampiran?adld_id=" +
          this.adld_id_tl + 
          "&adld_id_arsip_dokumen_log=" + this.adld_id_arsip_dokumen_log_tl + 
          "&adld_id_arsip_dokumen=" + this.adld_id_arsip_dokumen_tl+ 
          "&id_penerima=" + this.adld_id_penerima_tl
      )
      .then((response) => {
        var response_data = response.data;
        var response_data_fix = response_data.list_data;
        if (response_data.code == 200) {
          var lampiran_tindak_lanjut = response_data_fix;
          const clear_lampiran_tindak_lanjut= [];
          $.each(lampiran_tindak_lanjut, function (indexInArray, valueOfElement) { 
            clear_lampiran_tindak_lanjut.push({
              'adld_id': valueOfElement.adld_id,
              'adld_id_arsip_dokumen_log': valueOfElement.adld_id_arsip_dokumen_log,
              'adld_id_arsip_dokumen': valueOfElement.adld_id_arsip_dokumen,
              'adld_id_penerima': valueOfElement.id_penerima,
              'adld_catatan_tindaklanjut': valueOfElement.catatan_tindaklanjut,
              'file_tindaklanjut': valueOfElement.file_tindaklanjut,
              'password': valueOfElement.password_file,
            })
          });
          
          this.tindak_lanjut = clear_lampiran_tindak_lanjut;
          this.lampiran_db = this.tindak_lanjut.length;
          
          this.loadingTable= false;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data.message,
          });
        }
        Swal.close();
      });
    },
    addtindaklanjut() {
      this.tindak_lanjut.push({
        adld_id: this.adld_id_tl,
        adld_id_arsip_dokumen_log: this.adld_id_arsip_dokumen_log_tl,
        adld_id_arsip_dokumen: this.adld_id_arsip_dokumen_tl,
        adld_id_penerima: this.adld_id_penerima_tl,
        adld_catatan_tindaklanjut: '',
        file_tindaklanjut: '',
        password: '',
      });
    },
    removetindaklanjut(index) {
      this.tindak_lanjut.splice(index, 1);
    },
    uploadFileTindakLanjut(row) {
      let self = this;
      if ($("#upload_file_lampiran_tindak_lanjut"+row+"")[0].files[0]) {
        if ($("#upload_file_lampiran_tindak_lanjut"+row+"")[0].files[0].size < 30000000) {
          $("#upload_file_loading_lampiran_tindak_lanjut"+row+"").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;

          var apiurl = process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/uploadpdfwithqrcode";
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#upload_file_lampiran_tindak_lanjut"+row+"")[0].files[0]);
          data.append("q", 'yes');
          if(self.sifat_surat == 'biasa'){
            data.append("e", '');
          }else{
            data.append("e", 'yes');
          }
          data.append("t", 'masuk');
          
          var config = {
            method: "post",
            url: apiurl,
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            //console.log(response);
            var dir_name = response.data[0].file_upload.message.dir;
            urlres += dir_name;
            $("#upload_file_loading_lampiran_tindak_lanjut"+row+"").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );

            self.tindak_lanjut[row].file_tindaklanjut = dir_name;
            self.tindak_lanjut[row].password = response.data[0].password;
          }).catch((e) => {
            Swal.fire({
              icon: "error",
              title: "Gagal upload file",
              text: e.response?.data?.message?.file,
            });
          });
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "File Upload Lebih Dari 30Mb",
          });
        }
      }
    },
    StoreData() {
      let self = this;

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      if(self.tindak_lanjut.length == 0){
        Swal.fire({
          icon: 'error',
          title: 'Gagal!',
          text: "Pastikan formulir terisi lengkap",
        });
        return false;
      }
      if(self.tindak_lanjut[0].adld_catatan_tindaklanjut==null || self.tindak_lanjut[0].adld_catatan_tindaklanjut == '' || self.tindak_lanjut[0]?.file_tindaklanjut == null || self.tindak_lanjut[0]?.file_tindaklanjut == ''){
        Swal.fire({
          icon: 'error',
          title: 'Gagal!',
          text: "Pastikan formulir terisi lengkap",
        });
        return false;
      }
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id_arsip_dokumen", self.adld_id_arsip_dokumen_tl);
      data.append("adld_id_arsip_dokumen_log", self.adld_id_arsip_dokumen_log_tl);
      data.append("id_user_login", Cookies.get("session_id"));
      data.append("tindaklanjut", JSON.stringify(self.tindak_lanjut));

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION + "api/arsip-dokumen/suratmasuk/storetindaklanjut",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman detail surat masuk segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$emit("refresh-table", "add");
                Swal.close();
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    
    downloadEachAttachment(url) {
      const link = document.createElement("a");
      link.setAttribute('target', '_blank');
      var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
      urlres += url;
      link.href = urlres;
      link.click();
    },
  },
};
</script>

<template>
  <b-form class="p-2" @submit.prevent="StoreData">
    <table class="table mb-0 table-bordered table-condensed table-hover" id="myTable">
      <thead class="text-center bg-light">
        <tr>
          <td>Catatan</td>
          <td>File</td>
          <td>
            <div class="btn btn-success btn-sm" v-on:click="addtindaklanjut()">
              <i class="bx bx-plus"></i>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loadingTable">
          <td colspan="3"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
        </tr>
        <tr v-else-if="tindak_lanjut.length == 0">
          <td colspan="3">TIdak Ada Data</td>
        </tr>
        <tr v-else v-for="(item, index) in tindak_lanjut" :key="index">
          <td style="width: 400px">
            <input type="hidden" class="form-control" v-model="item.adld_id" />
            <input type="hidden" class="form-control" v-model="item.adld_id_arsip_dokumen_log" />
            <input type="hidden" class="form-control" v-model="item.adld_id_arsip_dokumen" />
            <input type="hidden" class="form-control" v-model="item.adld_id_penerima" />
            <input type="hidden" class="form-control" v-model="item.password" />
            <input type="text" class="form-control" v-model="item.adld_catatan_tindaklanjut" />
          </td>
          <td>
            <input type="file" class="form-control" :id="'upload_file_lampiran_tindak_lanjut' + index" @change="uploadFileTindakLanjut(index)"
              accept="application/pdf">
            <input type="hidden" v-model="item.file_tindaklanjut">
            <div v-if="lampiran_db >= index + 1 && lampiran_db != 0">
              <div class="respond-input-file float-left" :id="'upload_file_loading_lampiran_tindak_lanjut'+index">
                <div class="btn btn-info btn-sm mt-1" v-if="item.file_tindaklanjut" @click="downloadEachAttachment(item.file_tindaklanjut)">
                  <i class="fa fa-download"></i> Download
                </div>
                <span v-if="is_password">Password: {{ item.password }}</span>
              </div>
            </div>
            <div v-else>
              <div class="respond-input-file float-left" :id="'upload_file_loading_lampiran_tindak_lanjut'+index">
              </div>
            </div>
          </td>
          <td class="text-center">
            <button type="button" class="btn btn-danger btn-sm" @click="removetindaklanjut(index)">
              <i class="fa fa-minus"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-md-12 text-end">
        <b-button type="submit" variant="primary" class="m-1"
          ><i class="fa fa-save"></i> Simpan Tindak Lanjut</b-button
        >
      </div>
    </div>
  </b-form>
</template>
